import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import infoStyle from "assets/jss/material-kit-react/components/infoStyle.jsx";

function InfoArea({ ...props }) {
    const { classes, title, description, iconColor, vertical } = props;
    const iconWrapper = classNames({
        [classes.iconWrapper]: true,
        [classes[iconColor]]: true,
        [classes.iconWrapperVertical]: vertical
    });
    const iconClasses = classNames({
        [classes.icon]: true,
        [classes.iconVertical]: vertical
    });
    return (
        <div className={classes.infoArea}>
            <div className={iconWrapper}>
                <props.icon className={iconClasses} />
            </div>
            <div className={classes.descriptionWrapper}>
                <h4 className={classes.title}>{title}</h4>
                <div className={classes.description}>{description}</div>
            </div>
        </div>
    );
}

InfoArea.defaultProps = {
    iconColor: "gray"
};

InfoArea.propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
    iconColor: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    vertical: PropTypes.bool
};

export default withStyles(infoStyle)(InfoArea);
