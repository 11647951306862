import { container } from "assets/jss/material-kit-react.jsx";

const ImageGalleryStyle = {
    section: {
        padding: "1em 0em 2em 0em"
    },
    container,
    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important"
    },
    pointer: {
        cursor: "pointer"
    },
    slickCurrent: {
        border: "groove"
    }
};

export default ImageGalleryStyle;
