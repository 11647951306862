import React from "react"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import { urlFor } from "utils/image-url"
import pictureCardStyle from "assets/jss/material-kit-react/views/componentsSections/pictureCardStyle.jsx"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import PortableText from "./PortableText"

class PictureCardEmbed extends React.Component {
    render() {
        const { classes, node } = this.props
    
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Card plain>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.itemGrid}
                            >                <img
                                    src={urlFor(node.image)
                                        .url()}
                                    alt={node.title}
                                    className={
                                        classes.imgRaised +
                    ` ` + classes.imgFluid }
                                />
                            </GridItem>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                        <Card plain>
                            <GridItem>
                                <div className={classes.description}>
                                    <PortableText blocks={node.text} />
                                </div>
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>        
        )
    }
}

export default withStyles(pictureCardStyle)(PictureCardEmbed)
