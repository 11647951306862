import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"
import { Helmet } from "react-helmet"
import Footer from "components/Footer/Footer.jsx"

import "typeface-roboto"
import "typeface-roboto-slab"
/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
// core components

import TeamSection from "./LandingPage/Sections/TeamSection.jsx"
import ProductSection from "./LandingPage/Sections/ProductSection.jsx"
import SectionCarousel from "./LandingPage/Sections/SectionCarousel.jsx"

const Index = ({ data, location, pageContext }) => {
    const pageBlocks = data.sanitySiteSettings._rawBody.filter(m => !m.disabled)
    const posts = data.allSanityPost.edges.filter(function (item) {
        if (item.node.slug) {
            return true
        } else {
            return false
        }
    })

    const addresses = data.sanitySiteSettings._rawAddress

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/php8vfy.css"
                />
            </Helmet>
            <MetaData location={location} />
            <Layout isHome={true} mainImage={data.sanitySiteSettings.mainImage}>
                <TeamSection pageBlocks={pageBlocks}></TeamSection>
                <SectionCarousel data={posts}></SectionCarousel>
            </Layout>
            <ProductSection addresses={addresses}></ProductSection>
            <Footer/>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allSanityPost: PropTypes.object.isRequired,
        sanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
    query SanityPostQuery {
        allSanityPost(sort: {order: DESC, fields: publishOrder}, filter: {categories: {elemMatch: {slug: {current: {eq: "news"}}}}}) {
            edges {
                node {
                    title
                    mainImage {
                        crop {
                            _key
                            _type
                            top
                            bottom
                            left
                            right
                        }
                        hotspot {
                            _key
                            _type
                            x
                            y
                            height
                            width
                        }
                        asset {
                            _id
                        }
                    }
                    _rawExcerpt
                    slug {
                        current
                    }
                }
            }
        }
        sanitySiteSettings {
            mainImage {
                crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                }
                hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                }
                asset {
                    _id
                }
            }
            _rawAddress(resolveReferences: { maxDepth: 10 })
            _rawBody(resolveReferences: { maxDepth: 10 })
        }
    }
`
