import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import url from "url"

import config from "../../../utils/siteConfig"
import ArticleMeta from "./ArticleMeta"
import WebsiteMeta from "./WebsiteMeta"

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 *
 */
const MetaData = ({ data, settings, title, description, image, location }) => {
    const canonical = url.resolve(config.siteUrl, location.pathname)
    const { sanityPost, sanityCategory } = data
    settings = settings.allSanitySiteSettings.edges[0].node

    if (sanityPost) {
        return <ArticleMeta data={sanityPost} canonical={canonical} />
    } else if (sanityCategory) {
        return (
            <WebsiteMeta data={sanityCategory} canonical={canonical} image={image} type="Series" />
        )
    } else {
        title = title || config.siteTitleMeta || settings.title
        description =
            description || config.siteDescriptionMeta || settings.description
        image = image || settings.cover_image || null

        // image = image ? url.resolve(config.siteUrl, image) : null

        return (
            <WebsiteMeta
                data={{}}
                canonical={canonical}
                title={title}
                description={description}
                image={image}
                type="WebSite"
            />
        )
    }
    
    return <></>
};

MetaData.defaultProps = {
    data: {},
}

MetaData.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.object,
        sanityCategory: PropTypes.object,
        ghostAuthor: PropTypes.object,
        ghostPage: PropTypes.object,
        sanityPost: PropTypes.object,
    }).isRequired,
    settings: PropTypes.shape({
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
}

const MetaDataQuery = props => (
    <StaticQuery
        query={graphql`
            query SanitySettingsMetaData {
                allSanitySiteSettings {
                    edges {
                        node {
                            title
                            description
                        }
                    }
                }
            }
        `}
        render={data => <MetaData settings={data} {...props} />}
    />
)

export default MetaDataQuery
