import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage, getFixedGatsbyImage } from "gatsby-source-sanity";
import { clientConfig } from "../../../sanity-config";

export default ({ node }) => {
    const fluidProps = getFluidGatsbyImage(
        node.asset._ref,
        { maxWidth: 1200 },
        clientConfig
    );

    return (
        <figure>
            <Img fluid={fluidProps} alt={node.alt} />
            <div>
                <i>{node.caption}</i>
            </div>
        </figure>
    );
};
