import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
import Slider from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import ImageGalleryStyle from "assets/jss/material-kit-react/views/componentsSections/imageGalleryStyle.jsx";
import { urlFor } from "utils/image-url";

class ImageGalleryEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        function NextArrow(props) {
            return (
                <div style={{ float: "left" }}>
                    <button
                        onClick={props.onClick}
                        type="button"
                        className={`btn btn-default ${props.className}`}
                        style={props.style.button}
                    >
                        <i
                            className="fa fa-3x fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0px", color: "black" }}
                        ></i>
                    </button>
                </div>
            );
        }

        function PrevArrow(props) {
            return (
                <div>
                    <button
                        onClick={props.onClick}
                        type="button"
                        className={`btn btn-default ${props.className}`}
                        style={props.style.button}
                    >
                        <i
                            className="fa fa-3x fa-angle-left"
                            aria-hidden="true"
                            style={{ marginLeft: "0px" }}
                        ></i>
                    </button>
                </div>
            );
        }

        const { classes, node } = this.props;
        const sliderItemsCounts = 5;
        const slidesToShow =
            node.images.length < sliderItemsCounts
                ? node.images.length
                : sliderItemsCounts;
        const sizeOfScrollBlock = Math.round(
            (slidesToShow / sliderItemsCounts) * 12
        );

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            prevArrow: <PrevArrow style={{ color: "black" }} />,
            nextArrow: <NextArrow style={{ color: "black" }} />
        };
        const settings2 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            centerMode: true,
            prevArrow: <PrevArrow style={{ color: "black" }} />,
            nextArrow: <NextArrow style={{ color: "black" }} />
        };
        return (
            <div className={classes.section}>
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.marginAuto}
                    >
                        <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            {...settings}
                        >
                            {node.images.map((image, i) => (
                                <GridItem xs={12} sm={12} md={12} key={i}>
                                    <img
                                        className="slick-image"
                                        alt={image.alt}
                                        src={urlFor(image).fit('fill').ignoreImageParams().height(630).width(1024).bg('FFFFFF').url()}
                                    />
                                    <div>
                                        <h4>
                                            <i>{image.alt}</i>
                                        </h4>
                                    </div>
                                </GridItem>
                            ))}
                        </Slider>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem
                        xs={sizeOfScrollBlock}
                        sm={sizeOfScrollBlock}
                        md={sizeOfScrollBlock}
                        className={classes.marginAuto}
                    >
                        <Slider
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            focusOnSelect={true}
                            {...settings2}
                        >
                            {node.images.map((image, i) => (
                                <div
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    key={i}
                                    className="slick-under"
                                >
                                    <img
                                        className={`slick-image slick-under ${classes.pointer}`}
                                        alt={image.alt}
                                        src={urlFor(image)
                                            .width(320)
                                            .height(240)
                                            .url()}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(ImageGalleryStyle)(ImageGalleryEmbed);
