import React from "react";
import { clientConfig } from "../../../sanity-config";
import BasePortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

const PortableText = ({blocks}) => (
    <BasePortableText
        blocks={blocks}
        serializers={serializers}
        {...clientConfig}
    />
);

export default PortableText;
