import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import ImageGalleryStyle from "assets/jss/material-kit-react/views/componentsSections/imageGalleryStyle.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { urlForFile } from "utils/image-url";

class FilesGalleryEmbed extends React.Component {
    constructor(props) {
        super(props);
    }
      

    render() {
        const { classes, node } = this.props;
        return (
            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders">
                    {node.files.map((obj, i) => (  
                        <ListItem component="a" href={urlForFile(obj.asset._ref)} target="_blank" key={i} button>
                            <ListItemIcon>
                                <PictureAsPdf />
                            </ListItemIcon>
                            <ListItemText primary={obj.caption} />
                        </ListItem>
                    ))
                    }
                </List>
                <Divider />
            </div>
        );
    }
}

export default withStyles(ImageGalleryStyle)(FilesGalleryEmbed);
