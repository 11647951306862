import React from "react";
// react component for creating beautiful carousel
import IconButton from "@material-ui/core/IconButton";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// react component for creating beautiful carousel
import Carousel from "react-slick";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";
import { urlFor } from "utils/image-url";
import PortableText from "components/common/PortableText.js";
import { Link } from "gatsby";

// Import css files
import "slick-carousel/slick/slick.css";
//  import "slick-carousel/slick/slick-theme.css";

class SectionCarousel extends React.Component {
    render() {
        function NextArrow(props) {
            return (
                <div style={{ float: "left" }}>
                    <button
                        onClick={props.onClick}
                        type="button"
                        className={`btn btn-default ${props.className}`}
                        style={props.style.button}
                    >
                        <i
                            className="fa fa-3x fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0px", color: "black" }}
                        ></i>
                    </button>
                </div>
            );
        }

        function PrevArrow(props) {
            return (
                <div>
                    <button
                        onClick={props.onClick}
                        type="button"
                        className={`btn btn-default ${props.className}`}
                        style={props.style.button}
                    >
                        <i
                            className="fa fa-3x fa-angle-left"
                            aria-hidden="true"
                            style={{ marginLeft: "0px" }}
                        ></i>
                    </button>
                </div>
            );
        }

        const { classes, data } = this.props;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 3,
            autoplay: false,
            arrows: true,
            prevArrow: <PrevArrow style={{ color: "black" }} />,
            nextArrow: <NextArrow style={{ color: "black" }} />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false
                    }
                }
            ]
        };
        return (
            <div className={classes.section}>
                <div xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                    <h2 className={classes.title}>Latest news</h2>
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Carousel {...settings}>
                            {data.map(function (item, i) {
                                const pre = item.node.slug.current;
                                return (
                                    <GridItem xs={12} sm={12} md={12} key={i}>
                                        <Card plain carousel>
                                            <Link
                                                to={
                                                    "/" + item.node.slug.current
                                                }
                                            >
                                                <img
                                                    src={urlFor(
                                                        item.node.mainImage
                                                    )
                                                        .width(640)
                                                        .height(420)
                                                        .url()}
                                                    alt="Second slide"
                                                    className={
                                                        classes.imgRaised +
                                                        " slick-image " +
                                                        classes.imgFluid
                                                    }
                                                />
                                                <h4 className={classes.title}>
                                                    {item.node.title}
                                                </h4>
                                                <span
                                                    className={
                                                        classes.description
                                                    }
                                                ></span>
                                                <PortableText
                                                    blocks={
                                                        item.node._rawExcerpt
                                                    }
                                                />
                                            </Link>
                                        </Card>
                                    </GridItem>
                                );
                            })}
                        </Carousel>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(carouselStyle)(SectionCarousel);
