import React from "react"
import PropTypes from "react"

// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import { graphql } from "gatsby"
// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import PortableText from "components/common/PortableText.js"

import { urlFor } from "utils/image-url"

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx"

class TeamSection extends React.Component {
    render() {
        const { classes, pageBlocks } = this.props
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card plain>
                            <CardHeader>
                                <h2 className={classes.title}>
                                    {pageBlocks[0].title}
                                </h2>
                            </CardHeader>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.itemGrid}
                            >
                                <img
                                    src={urlFor(pageBlocks[0].image)
                                        .url()}
                                    alt={pageBlocks[0].title}
                                    className={
                                        classes.imgRaised +
                                        ` ` +
                                        classes.imgFluid
                                    }
                                />
                            </GridItem>
                            <CardBody>
                                <div className={classes.description}>
                                    <PortableText blocks={pageBlocks[0].text} />
                                </div>
                            </CardBody>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.itemGrid}
                            >
                                <img
                                    src={urlFor(pageBlocks[0].imageBottom).url()}
                                    alt={pageBlocks[0].title}
                                    className={
                                        classes.imgRaised +
                                        ` ` +
                                        classes.imgFluid
                                    }
                                />
                            </GridItem>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card plain>
                            <CardHeader>
                                <h2 className={classes.title}>
                                    {pageBlocks[1].title}
                                </h2>
                            </CardHeader>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.itemGrid}
                            ><iframe src={`https://calendar.google.com/calendar/embed?ctz=America%2FChicago&showTitle=0&mode=AGENDA&showPrint=0&showTabs=0&showCalendars=0&showDate=0&showNav=1&showTz=0&src=ssocdallas%40gmail.com`}
                                width={`100%`} height={`450`} frameborder={`0`} scrolling={`no`}></iframe>
                            </GridItem>
                            <CardBody>
                                <div className={classes.description}>
                                    <PortableText blocks={pageBlocks[1].text} />
                                </div>
                            </CardBody>
                        </Card>                    
                    </GridItem>
                    {pageBlocks.length > 2 &&
                    <GridItem xs={12} sm={12} md={12} className="justify-content-center" style={{ background: `#fbf8e9` }}>
                        <Card plain>
                            <CardHeader>
                                <h2 className={classes.title}>
                                    {pageBlocks[2].title}
                                </h2>
                            </CardHeader>
                            <CardBody>
                                <div className={classes.description}>
                                    <PortableText blocks={pageBlocks[2].text} />
                                </div>
                            </CardBody>
                            <GridItem
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.itemGrid + ` justify-content-center`}
                            ><a href={pageBlocks[2].link} target="_blank">
                                    <img
                                        src={urlFor(pageBlocks[2].image)
                                            .url()}
                                        alt={pageBlocks[2].title}
                                        className={
                                            classes.imgRaised +
                                        ` ` +
                                        classes.imgFluid
                                        }
                                    />
                                </a>
                            </GridItem>
                        </Card>                    
                    </GridItem>
                    }
                </GridContainer>
            </div>
        )
    }
}

export default withStyles(teamStyle)(TeamSection)
