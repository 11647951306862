/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const cardBodyStyle = {
    cardBody: {
        padding: "0.9375rem 1.875rem",
        flex: "1 1 auto",
        textAlign: "justify",
        textJustify: "inter-word"
    }
};

export default cardBodyStyle;
