import React from 'react'
import Helmet from "react-helmet"
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import url from 'url'

// import getAuthorProperties from './getAuthorProperties'
import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'

import { tags as tagsHelper } from '@tryghost/helpers'

const ArticleMetaSanity = ({ data, settings, canonical }) => {
    const sanityPost = data
    settings = settings.allSanitySiteSettings.edges[0].node

    // const author = getAuthorProperties(sanityPost.primary_author)
    const publicTags = _.map(tagsHelper(sanityPost, { visibility: `public`, fn: tag => tag }), `name`)
    const primaryTag = publicTags[0] || ``
    const shareImage = sanityPost.mainImage
    const publisherLogo = (settings.logo || config.siteIcon) ? url.resolve(config.siteUrl, (settings.logo || config.siteIcon)) : null

    let description = ``
    if (sanityPost.meta_description) {
        sanityPost.meta_description.map(function (item) {
            item.children.map(function (child){
                description += child.text
            }, description)
        }, description
        )
    }

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Article`,
        // author: {
        //     "@type": `Person`,
        //     name: author.name,
        //     image: author.image ? author.image : undefined,
        //     sameAs: author.sameAsArray ? author.sameAsArray : undefined,
        // },
        keywords: publicTags.length ? publicTags.join(`, `) : undefined,
        headline: sanityPost.meta_title || sanityPost.title,
        url: canonical,
        datePublished: sanityPost.published_at_og || sanityPost.updated_at_og,
        dateModified: sanityPost.updated_at_og,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage.asset.url,
            width: shareImage.asset.metadata.dimensions.width,
            height: shareImage.asset.metadata.dimensions.height,
        } : undefined,
        publisher: {
            "@type": `Organization`,
            name: settings.title,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: 60,
                height: 60,
            },
        },
        description: description || sanityPost.meta_description || sanityPost.excerpt,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl,
        },
    }

    return (
        <>
            <Helmet>
                <title>{sanityPost.meta_title || sanityPost.title} - {config.shortTitle}</title>
                <meta name="description" content={description || sanityPost.meta_description || sanityPost.excerpt} />
                <link rel="canonical" href={canonical} />

                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="article" />
                <meta property="og:title"
                    content={
                        sanityPost.og_title ||
                        sanityPost.meta_title ||
                        sanityPost.title
                    }
                />
                <meta property="og:description"
                    content={
                        description ||
                        sanityPost.og_description ||
                        sanityPost.excerpt ||
                        sanityPost.meta_description
                    }
                />
                <meta property="og:url" content={canonical} />
                <meta property="article:published_time" content={sanityPost.published_at_og || sanityPost.updated_at_og} />
                <meta property="article:modified_time" content={sanityPost.updated_at_og} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}

                <meta name="twitter:title"
                    content={
                        sanityPost.twitter_title ||
                        sanityPost.meta_title ||
                        sanityPost.title
                    }
                />
                <meta name="twitter:description"
                    content={
                        description ||
                        sanityPost.twitter_description ||
                        sanityPost.excerpt ||
                        sanityPost.meta_description
                    }
                />
                <meta name="twitter:url" content={canonical} />
                {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content={primaryTag} />}

                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

ArticleMetaSanity.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        published_at_og: PropTypes.string,
        updated_at_og: PropTypes.string,
        meta_title: PropTypes.string,
        meta_description: PropTypes.array,
        primary_author: PropTypes.object,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                slug: PropTypes.string,
                visibility: PropTypes.string,
            })
        ),
        primaryTag: PropTypes.shape({
            name: PropTypes.string,
        }),
        og_title: PropTypes.string,
        og_description: PropTypes.array,
        twitter_title: PropTypes.string,
        twitter_description: PropTypes.array,
        excerpt: PropTypes.object,
        mainImage: PropTypes.object,
    }).isRequired,
    settings: PropTypes.shape({
        logo: PropTypes.object,
        title: PropTypes.string,
        twitter: PropTypes.string,
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

const ArticleMetaQuery = props => (
    <StaticQuery
        query={graphql`
            query SanitySettingsArticleMeta {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <ArticleMetaSanity settings={data} {...props} />}
    />
)

export default ArticleMetaQuery
