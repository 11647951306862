import React from "react"
import ReactPlayer from "react-player"

import YouTube from "react-youtube"
import getYouTubeId from "get-youtube-id"

const YouTubeEmbed = ({ node }) => {
    const { url } = node
    const id = getYouTubeId(url)
    return (
        <ReactPlayer url={url} controls={true} playing />
    )
}

export default YouTubeEmbed
