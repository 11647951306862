import React from "react";
import Figure from "./Figure";
import YouTube from "./YouTube";
import ImageGallery from "./ImageGallery";
import FilesGallery from "./FilesGallery";
import PictureCard from "./PictureCard";

const serializers = {
    types: {
        authorReference: ({ node }) => <span>{node.author.name}</span>,
        mainImage: Figure,
        youtube: YouTube,
        imageGallery: ImageGallery,
        filesGallery: FilesGallery,
        pictureCard: PictureCard,
    }
};

export default serializers;
