import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
    render() {
        const { classes, addresses } = this.props;
        const iconDict = {
            Chat: Chat,
            BusinessCenter: BusinessCenter,
            LocalLibrary: LocalLibrary,
            VerifiedUser: VerifiedUser,
            Fingerprint: Fingerprint
        };
        const colorArray = ["info", "success", "danger", "info", "info"];

        return (
            <div className={classes.section}>
                <GridItem container>
                    {addresses.map(function(address, i) {
                        return (
                            <GridItem xs={12} sm={12} md={4} key={i}>
                                <InfoArea
                                    key={i}
                                    title={address.title}
                                    description={address.text.map(function(
                                        child,
                                        k
                                    ) {
                                        return (
                                            <p key={k}>
                                                {child.children[0].text}
                                            </p>
                                        );
                                    })}
                                    icon={iconDict[address.icon]}
                                    iconColor={colorArray[i]}
                                    vertical
                                />
                            </GridItem>
                        );
                    })}
                </GridItem>
            </div>
        );
    }
}

export default withStyles(productStyle)(ProductSection);
